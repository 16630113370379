import { IDeviceInfo } from '@components/ui/context'
import {
  BestSellerProducts,
  BestSellerTitle,
  CategoryList,
  CategoryListData,
  ColumnImage,
  ColumnVideo,
  FavouritePayments,
  FavouritePaymentsData,
  HeroBanner,
  RichTextContent,
  TopCategory,
  getMidBannerTop,
  getMidBannerTopData,
  TypeForm,
} from './HomePageComponent'
import {
  CURRENT_HOME_COMPONENT_PAGE,
  FacebookEvents,
} from '@components/utils/constants'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import {
  getBrowserIdForFBConversion,
  getCurrentPage,
  getDateOfBirthHash,
  getGenderHash,
  getIpAddressForFBConversion,
} from '@framework/utils/app-util'
import { getSHA256Hash } from '@framework/utils/cipher'
import OfferBanners from './OfferBanners'

export enum VIEW_TYPE {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export const getComponents = (
  key: any,
  pageContents: any,
  deviceInfo: IDeviceInfo,
  showPadding: boolean,
  currentPage: string,
  cmsNoPaddingLayout: boolean = false
) => {
  switch (key) {
    case 'topcategory':
      if (currentPage !== CURRENT_HOME_COMPONENT_PAGE.HOME) {
        return <TopCategory data={pageContents['topcategory']} />
      } else {
        return null
      }
    case 'banner':
      return (
        <HeroBanner
          data={pageContents?.banner}
          componentName="banner"
          deviceInfo={deviceInfo}
        />
      )
    case 'topcategory22':
      return <TopCategory data={pageContents['topcategory22']} />
    case 'topcategory2':
      return <TopCategory data={pageContents['topcategory2']} />
    case 'herobanner2':
      return (
        <HeroBanner
          data={pageContents?.herobanner2}
          componentName="herobanner2"
          deviceInfo={deviceInfo}
        />
      )
    case 'herobanner3':
      return (
        <HeroBanner
          data={pageContents?.herobanner3}
          componentName="herobanner3"
          deviceInfo={deviceInfo}
        />
      )
    case 'herobanner4':
      return (
        <HeroBanner
          data={pageContents?.herobanner4}
          componentName="herobanner4"
          deviceInfo={deviceInfo}
        />
      )
    case 'paymentoffers':
      return <FavouritePaymentsData data={pageContents} />
    case 'paymentheading':
      return <FavouritePayments data={pageContents} />
    case 'categoryheading':
      return <CategoryList data={pageContents} index={0} />
    case 'categoryheading1':
      return <CategoryList data={pageContents} index={1} />
    case 'categoryheading2':
      return <CategoryList data={pageContents} index={2} />
    case 'categoryheading3':
      return <CategoryList data={pageContents} index={3} />
    case 'categoryheading4':
      return <CategoryList data={pageContents} index={4} />
    case 'categoryheading5':
      return <CategoryList data={pageContents} index={5} />
    case 'categoryheading6':
      return <CategoryList data={pageContents} index={6} />
    case 'categoryheading7':
      return <CategoryList data={pageContents} index={7} />
    case 'categoryheading8':
      return <CategoryList data={pageContents} index={8} />
    case 'categoryheading9':
      return <CategoryList data={pageContents} index={9} />
    case 'categoryheading10':
      return <CategoryList data={pageContents} index={10} />
    case 'categorylist':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={0}
        />
      )
    case 'categorylist1':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={1}
        />
      )
    case 'categorylist2':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={2}
        />
      )
    case 'categorylist3':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={3}
        />
      )
    case 'categorylist4':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={4}
        />
      )
    case 'categorylist5':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={5}
        />
      )
    case 'categorylist6':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={6}
        />
      )
    case 'categorylist7':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={7}
        />
      )
    case 'categorylist8':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={8}
        />
      )
    case 'categorylist9':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={9}
        />
      )
    case 'categorylist10':
      return (
        <CategoryListData
          data={pageContents}
          columnCount={4.25}
          deviceInfo={deviceInfo}
          index={10}
        />
      )
    case 'offerbanners':
      return <OfferBanners data={pageContents} />
    case 'bestsellertitle':
      return <BestSellerTitle data={pageContents} type="bestsellertitle" />
    case 'bestseller':
      return (
        <BestSellerProducts
          data={pageContents}
          collection={pageContents['bestseller']}
          bannerColor={'indigo-500'}
          deviceInfo={deviceInfo}
          type="bestsellerheading"
          viewType={VIEW_TYPE.HORIZONTAL}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case 'bestsellervertical':
      return (
        <BestSellerProducts
          data={pageContents}
          collection={pageContents['bestsellervertical']}
          bannerColor={'indigo-500'}
          deviceInfo={deviceInfo}
          type="bestsellerheading"
          viewType={VIEW_TYPE.VERTICAL}
          redirectionUrl={pageContents['bestsellerverticalplpslug']}
        />
      )
    case 'productcarousel2title':
      return (
        <BestSellerTitle data={pageContents} type="productcarousel2title" />
      )
    case 'productcarousel2':
      return (
        <BestSellerProducts
          data={pageContents}
          collection={pageContents['productcarousel2']}
          bannerColor={'indigo-500'}
          deviceInfo={deviceInfo}
          type="productcarousel2title"
        />
      )
    case '1colimage1':
      return (
        <ColumnImage
          data={pageContents?.['1colimage1']}
          componentName="1colimage1"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage2':
      return (
        <ColumnImage
          data={pageContents?.['1colimage2']}
          componentName="1colimage2"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={2}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage3':
      return (
        <ColumnImage
          data={pageContents?.['1colimage3']}
          componentName="1colimage3"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={3}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage4':
      return (
        <ColumnImage
          data={pageContents?.['1colimage4']}
          componentName="1colimage4"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={4}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage5':
      return (
        <ColumnImage
          data={pageContents?.['1colimage5']}
          componentName="1colimage5"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={5}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage6':
      return (
        <ColumnImage
          data={pageContents?.['1colimage6']}
          componentName="1colimage6"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={6}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage7':
      return (
        <ColumnImage
          data={pageContents?.['1colimage7']}
          componentName="1colimage7"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={7}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage8':
      return (
        <ColumnImage
          data={pageContents?.['1colimage8']}
          componentName="1colimage8"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={8}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage9':
      return (
        <ColumnImage
          data={pageContents?.['1colimage9']}
          componentName="1colimage9"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={9}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '1colimage10':
      return (
        <ColumnImage
          data={pageContents?.['1colimage10']}
          componentName="1colimage10"
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={10}
          showPadding={showPadding}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case '4colimage1':
      return (
        <ColumnImage
          data={pageContents?.['4colimage1']}
          componentName="4colimage1"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
        />
      )
    case '4colimage2':
      return (
        <ColumnImage
          data={pageContents?.['4colimage2']}
          componentName="4colimage2"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={2}
          showPadding={showPadding}
        />
      )
    case '4colimage3':
      return (
        <ColumnImage
          data={pageContents?.['4colimage3']}
          componentName="4colimage3"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={3}
          showPadding={showPadding}
        />
      )
    case '4colimage4':
      return (
        <ColumnImage
          data={pageContents?.['4colimage4']}
          componentName="4colimage4"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={4}
          showPadding={showPadding}
        />
      )
    case '4colimage5':
      return (
        <ColumnImage
          data={pageContents?.['4colimage5']}
          componentName="4colimage5"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={5}
          showPadding={showPadding}
        />
      )
    case '4colimage6':
      return (
        <ColumnImage
          data={pageContents?.['4colimage6']}
          componentName="4colimage6"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={6}
          showPadding={showPadding}
        />
      )
    case '4colimage7':
      return (
        <ColumnImage
          data={pageContents?.['4colimage7']}
          componentName="4colimage7"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={7}
          showPadding={showPadding}
        />
      )
    case '4colimage8':
      return (
        <ColumnImage
          data={pageContents?.['4colimage8']}
          componentName="4colimage8"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={8}
          showPadding={showPadding}
        />
      )
    case '4colimage9':
      return (
        <ColumnImage
          data={pageContents?.['4colimage9']}
          componentName="4colimage9"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={9}
          showPadding={showPadding}
        />
      )
    case '4colimage10':
      return (
        <ColumnImage
          data={pageContents?.['4colimage10']}
          componentName="4colimage10"
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={10}
          showPadding={showPadding}
        />
      )
    case 'typeform':
      return (
        <TypeForm
          data={pageContents?.['typeform']}
          componentName="typeform"
          deviceInfo={deviceInfo}
        />
      )
    case `1colvideo${pageContents?.colvideoview}`:
      return (
        <ColumnVideo
          data={pageContents?.[`1colvideo${pageContents?.colvideoview}`]}
          componentName={`1colvideo${pageContents?.colvideoview}`}
          columnCount={1}
          mobileRowCount={1}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
          view={pageContents?.colvideoview}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case `2colvideo${pageContents?.colvideoview}`:
      return (
        <ColumnVideo
          data={pageContents?.[`2colvideo${pageContents?.colvideoview}`]}
          componentName={`2colvideo${pageContents?.colvideoview}`}
          columnCount={2}
          mobileRowCount={2}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
          view={pageContents?.colvideoview}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case `3colvideo${pageContents?.colvideoview}`:
      return (
        <ColumnVideo
          data={pageContents?.[`3colvideo${pageContents?.colvideoview}`]}
          componentName={`3colvideo${pageContents?.colvideoview}`}
          columnCount={3}
          mobileRowCount={3}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
          view={pageContents?.colvideoview}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case `4colvideo${pageContents?.colvideoview}`:
      return (
        <ColumnVideo
          data={pageContents?.[`4colvideo${pageContents?.colvideoview}`]}
          componentName={`4colvideo${pageContents?.colvideoview}`}
          columnCount={4}
          mobileRowCount={4}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
          view={pageContents?.colvideoview}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case `5colvideo${pageContents?.colvideoview}`:
      return (
        <ColumnVideo
          data={pageContents?.[`5colvideo${pageContents?.colvideoview}`]}
          componentName={`5colvideo${pageContents?.colvideoview}`}
          columnCount={5}
          mobileRowCount={5}
          deviceInfo={deviceInfo}
          postCount={1}
          showPadding={showPadding}
          view={pageContents?.colvideoview}
          cmsNoPaddingLayout={cmsNoPaddingLayout}
        />
      )
    case 'textcontent1':
      return <RichTextContent data={pageContents?.['textcontent1']} />
    case 'textcontent2':
      return <RichTextContent data={pageContents?.['textcontent2']} />
    case 'textcontent3':
      return <RichTextContent data={pageContents?.['textcontent3']} />
    case 'textcontent4':
      return <RichTextContent data={pageContents?.['textcontent4']} />
    case 'textcontent5':
      return <RichTextContent data={pageContents?.['textcontent5']} />
    case 'textcontent6':
      return <RichTextContent data={pageContents?.['textcontent6']} />
    case 'textcontent7':
      return <RichTextContent data={pageContents?.['textcontent7']} />
    case 'textcontent8':
      return <RichTextContent data={pageContents?.['textcontent8']} />
    case 'textcontent9':
      return <RichTextContent data={pageContents?.['textcontent9']} />
    case 'textcontent10':
      return <RichTextContent data={pageContents?.['textcontent10']} />
    case `1columnheading`:
      return getMidBannerTop(1, pageContents, deviceInfo)
    case '1columndata':
      return getMidBannerTopData(1, pageContents, deviceInfo, showPadding)
    case `2columnheading`:
      return getMidBannerTop(2, pageContents, deviceInfo)
    case '2columndata':
      return getMidBannerTopData(2, pageContents, deviceInfo, showPadding)
    case `3columnheading`:
      return getMidBannerTop(3, pageContents, deviceInfo)
    case '3columndata':
      return getMidBannerTopData(3, pageContents, deviceInfo, showPadding)
    case `4columnheading`:
      return getMidBannerTop(4, pageContents, deviceInfo)
    case '4columndata':
      return getMidBannerTopData(4, pageContents, deviceInfo, showPadding)
    case `5columnheading`:
      return getMidBannerTop(5, pageContents, deviceInfo)
    case '5columndata':
      return getMidBannerTopData(5, pageContents, deviceInfo, showPadding)
    case `6columnheading`:
      return getMidBannerTop(6, pageContents, deviceInfo)
    case '6columndata':
      return getMidBannerTopData(6, pageContents, deviceInfo, showPadding)
    case `7columnheading`:
      return getMidBannerTop(7, pageContents, deviceInfo)
    case '7columndata':
      return getMidBannerTopData(7, pageContents, deviceInfo, showPadding)
    case `8columnheading`:
      return getMidBannerTop(8, pageContents, deviceInfo)
    case '8columndata':
      return getMidBannerTopData(8, pageContents, deviceInfo, showPadding)
    default:
      return null
  }
}

export const pageView = async (user: any) => {
  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData: any = {
    data: [
      {
        event_name: FacebookEvents.PAGE_VIEW,
        event_time: Math.floor(new Date().getTime() / 1000),
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: null,
          zp: null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          content_name: getCurrentPage(),
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)
}
