import {
  getCurrentPage,
  getImageNameFromURL,
  removePrecedingSlash,
} from '@framework/utils/app-util'
import Link from 'next/link'
import { isMobileOnly } from 'react-device-detect'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IMG_PLACEHOLDER } from '../textVariables'
import 'swiper/css'
import 'swiper/css/navigation'
import IntersectionObserverComponent from '../IntersectionObserverComponent'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { EmptyGuid } from '../constants'
import { useUI } from '@components/ui'
import { recordGA4Event } from '@components/services/analytics/ga4'

interface IProps {
  activeTopStripOffers?: Array<any>
  handleRecordGA4Event: () => void
}

interface IBanner {
  current_page: string
  position: number
  banner_name: string
  component_type: string
  component_name: string
  url: string
  loggedin_status: boolean
  device: string
}

const generateBannerData = ({
  cate,
  categoryidx,
  user,
}: {
  cate: any
  categoryidx: number
  user: any
}) => {
  const link = cate?.offerstrip_link
  const webImage = cate?.offerstrip_heroimage

  const mobileImage = cate?.offerstrip_mobileheroimage
  const title = cate?.offerstrip_title
  const bannersData = {
    current_page: getCurrentPage() || '',
    position: categoryidx,
    banner_name: isMobileOnly
      ? mobileImage !== ''
        ? getImageNameFromURL(mobileImage)
        : getImageNameFromURL(webImage)
      : getImageNameFromURL(webImage),
    component_type: 'image',
    component_name: 'offerstrip',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    url: isMobileOnly
      ? mobileImage !== ''
        ? mobileImage
        : webImage
      : webImage,
    device: isMobileOnly ? 'Mobile' : 'Web',
  }
  return { bannersData, link, title, mobileImage, webImage }
}

const TopStripActiveOffers = (props: IProps) => {
  const { activeTopStripOffers, handleRecordGA4Event } = props
  const { user } = useUI()

  const handleBannerClickGAEvent = (banner: IBanner) => {
    recordGA4Event(window, GA_EVENT.BANNER_CLICK, banner)
  }

  const topStripBannerData = generateBannerData({
    user,
    cate: undefined,
    categoryidx: 0,
  })

  return (
    <Swiper
      slidesPerView={1}
      navigation={false}
      loop={false}
      freeMode={true}
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{
        dynamicBullets: true,
      }}
      className="mySwiper h-[20vw] sm:h-[20vw] md:h-auto"
    >
      <div key={0}>
        <SwiperSlide
          key={`${0}`}
          onClick={() =>
            handleBannerClickGAEvent(topStripBannerData.bannersData)
          }
        >
          <IntersectionObserverComponent
            title="Top Offer Strip"
            eventName={GA_EVENT.BANNER_IMPRESSIONS}
            bannerData={topStripBannerData.bannersData}
          >
            <div
              key={0}
              className={`bg-black text-white w-full h-[20vw] sm:h-[20vw] md:h-auto py-3 px-4 sm:px-4 lg:px-0 text-sm font-medium z-999 text-center mx-auto w-full download-banner-wrapper`}
            >
              <div className="relative max-w-full flex justify-between items-center mx-auto sm:max-w-full max-w-50">
                <div className="flex items-center gap-x-2">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="48" height="48" rx="8" fill="white" />
                    <path
                      d="M16.5553 16C16.7379 16.013 16.9202 16.0285 17.1029 16.0386C18.136 16.0964 19.1503 16.2468 20.129 16.5705C22.1537 17.2402 23.4421 18.554 24.0544 20.458C24.2983 21.2164 24.4238 21.9933 24.4817 22.7815C24.5353 23.51 24.5359 24.2382 24.4784 24.9663C24.3958 26.0128 24.195 27.0354 23.7643 28.0114C23.0064 29.729 21.6754 30.8808 19.7936 31.4867C18.9208 31.7677 18.0192 31.9168 17.099 31.9617C16.5846 31.9869 16.0689 31.9948 15.5537 31.9973C14.4591 32.0023 13.3645 31.9988 12.2699 31.9988C12.1931 31.9988 12.1162 31.9988 12.0272 31.9988C12.0171 31.9449 12.0051 31.9024 12.0022 31.8595C11.9982 31.8006 12.0009 31.7413 12.0009 31.6822C12.0009 26.5525 12.0008 21.423 12.0018 16.2934C12.0018 16.1955 12.0145 16.0978 12.0213 16C13.5326 16 15.044 16 16.5553 16ZM15.0363 17.7373C15.0363 21.9249 15.0363 26.0918 15.0363 30.2538C15.063 30.2631 15.0758 30.2714 15.0884 30.2712C15.8715 30.2632 16.6564 30.3202 17.4369 30.2271C18.3069 30.1234 19.0838 29.8261 19.719 29.2454C20.1333 28.8666 20.4333 28.4178 20.6625 27.9252C20.9849 27.2323 21.1587 26.5024 21.25 25.7568C21.4079 24.4668 21.418 23.1755 21.2139 21.8879C21.0991 21.1636 20.8974 20.4615 20.5326 19.8073C20.0656 18.9701 19.3768 18.3576 18.4199 18.0226C17.9138 17.8454 17.3872 17.7503 16.848 17.7367C16.2834 17.7224 15.7183 17.7301 15.1534 17.7288C15.1193 17.7289 15.0851 17.7338 15.0363 17.7373Z"
                      fill="black"
                    />
                    <path
                      d="M31.0119 16C31.1385 16.0129 31.2647 16.0297 31.3915 16.038C32.133 16.0868 32.8573 16.2091 33.5482 16.4751C35.0282 17.0449 35.8053 18.0988 35.962 19.5726C35.9908 19.8437 35.9982 20.1177 35.9984 20.3904C36.001 23.1354 36 25.8804 36 28.6255C36 28.7035 36 28.7815 36 28.87C35.0257 28.87 34.0764 28.87 33.1079 28.87C33.1079 28.4797 33.1079 28.0962 33.1079 27.7018C32.9923 27.7067 32.9432 27.7795 32.8825 27.8269C32.2378 28.3296 31.5127 28.6838 30.6925 28.8542C29.82 29.0353 28.9433 29.068 28.0757 28.8355C26.6932 28.465 25.8957 27.5917 25.6392 26.2785C25.5307 25.7232 25.5421 25.1652 25.6718 24.6115C25.8631 23.7951 26.341 23.1672 27.0909 22.7195C27.6939 22.3596 28.3614 22.1552 29.0591 22.0433C30.2983 21.8445 31.5408 21.6627 32.7818 21.4732C32.8578 21.4616 32.9327 21.444 33.025 21.4259C33.025 21.3154 33.025 21.2099 33.025 21.1043C33.025 20.8045 33.0255 20.5046 33.0246 20.2048C33.0235 19.8349 32.9611 19.4753 32.808 19.1325C32.5014 18.4462 31.923 18.0787 31.1718 17.9216C30.5593 17.7934 29.9421 17.8196 29.3264 17.898C28.4389 18.0109 27.5962 18.2618 26.7959 18.6386C26.7652 18.6531 26.7324 18.6639 26.6999 18.6767C26.6507 18.5329 26.6327 16.9794 26.6759 16.5409C26.9437 16.472 27.2201 16.3901 27.5018 16.3304C28.2725 16.1674 29.0514 16.0561 29.8428 16.0318C29.92 16.0295 29.9965 16.0112 30.0734 16.0002C30.3862 16 30.6991 16 31.0119 16ZM33.017 23.1053C32.9628 23.1053 32.9278 23.1024 32.8935 23.1057C32.1165 23.1804 31.3395 23.2547 30.5628 23.3314C30.1519 23.372 29.7473 23.438 29.3713 23.6109C28.9375 23.8102 28.6537 24.1258 28.5346 24.5653C28.4119 25.0185 28.4139 25.4746 28.5273 25.9291C28.6997 26.6194 29.1735 27.018 29.9062 27.1533C30.3502 27.2353 30.7974 27.2214 31.2414 27.1517C31.8886 27.0502 32.4792 26.8182 33.017 26.4551C33.017 25.3434 33.017 24.2332 33.017 23.1053Z"
                      fill="black"
                    />
                    <path
                      d="M35.9884 32C32.5021 32 29.0417 32 25.5654 32C25.5654 31.6702 25.5654 31.3488 25.5654 31.0128C25.6469 31.0086 25.7289 31.001 25.811 31.0008C26.2333 30.9995 26.6558 31.0002 27.0781 31.0002C29.9416 31.0002 32.8052 31.0001 35.6687 31.0003C36.0031 31.0003 36.0005 30.9872 36.0002 31.1801C35.9998 31.4049 36.0006 31.6296 35.9996 31.8544C35.9994 31.8972 35.9932 31.9399 35.9884 32Z"
                      fill="#EC5E28"
                    />
                  </svg>
                  <div className="flex flex-col justify-start items-center w-70 lg:w-full">
                    <span className="w-full text-left text-sm font-semibold text-white max-w-95 lg:max-w-full">
                      DaMENSCH APP
                    </span>
                    <span className="w-full text-left font-light text-xs text-white max-w-95 lg:max-w-full">
                      Easy Checkout & Free Shipping in App
                    </span>
                  </div>
                </div>
                <div
                  id="downloadApp"
                  className="absolute right-0 flex justify-center items-center w-35 lg:max-w-full"
                  onClick={() => {
                    handleRecordGA4Event()
                  }}
                >
                  <a
                    id="downloadAppLink"
                    href={'https://damensch.co.in/top-strip'}
                    target="_blank"
                  >
                    <button className="bg-orange text-white px-2 sm:px-2 lg:px-4 py-3 rounded-sm text-xs font-semibold">
                      Download App
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </IntersectionObserverComponent>
        </SwiperSlide>
      </div>
      {activeTopStripOffers &&
        activeTopStripOffers?.map((cate: any, categoryidx: number) => {
          const { bannersData, link, title, mobileImage, webImage } =
            generateBannerData({ cate, categoryidx, user })

          return (
            <div key={categoryidx + 1}>
              <SwiperSlide
                key={`${categoryidx}`}
                onClick={() => handleBannerClickGAEvent(bannersData)}
              >
                <IntersectionObserverComponent
                  title="Top Offer Strip"
                  eventName={GA_EVENT.BANNER_IMPRESSIONS}
                  bannerData={bannersData}
                >
                  <Link
                    legacyBehavior
                    href={`/${removePrecedingSlash(link)}`}
                    passHref
                  >
                    <div className="relative flex flex-col w-full rounded">
                      <div className="relative flex-1">
                        {isMobileOnly ? (
                          <>
                            {mobileImage != '' ? (
                              <img
                                src={mobileImage || IMG_PLACEHOLDER}
                                style={{
                                  width: '100%',
                                }}
                                alt={title || 'home banner'}
                                className="h-[20vw] sm:h-[20vw] md:h-auto"
                              />
                            ) : (
                              <img
                                src={webImage || IMG_PLACEHOLDER}
                                alt={title || 'home banner'}
                              />
                            )}
                          </>
                        ) : (
                          <img
                            src={webImage || IMG_PLACEHOLDER}
                            alt={title || 'home banner'}
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                </IntersectionObserverComponent>
              </SwiperSlide>
            </div>
          )
        })}
    </Swiper>
  )
}

export default TopStripActiveOffers
