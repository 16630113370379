import { useEffect } from 'react'
import DataLayerInstance, { KEYS_MAP } from '@components/utils/dataLayer'
import { Layout } from '@components/common'
import { OMNILYTICS_DISABLED } from '@framework/utils/constants'

export const PAGE_TYPES = {
  Blog: 'Blog',
  Brand: 'Brand',
  Category: 'Category',
  Checkout: 'Checkout',
  Common: 'Common',
  Home: 'Home',
  Page: 'Page',
  Product: 'Product',
  Search: 'Search',
  Survey: 'Survey',
  Thankyou: 'Thankyou',
  Payment: 'Payment',
}
export default function withDataLayer(
  Component: any,
  pageType: string,
  showLayout = true,
  defaultLayout = Layout
) {
  function WrappedComponent(props: any) {
    useEffect(() => {
      if (!OMNILYTICS_DISABLED)
        DataLayerInstance.setItemInDataLayer('pageCategory', pageType)
    }, [])

    const setEntities = (entities: any) => {
      if (!OMNILYTICS_DISABLED)
        DataLayerInstance.setEntities(entities)
    }
    const recordEvent = (event: string) =>
      DataLayerInstance.setItemInDataLayer(KEYS_MAP.eventType, event)

    return (
      <Component
        {...props}
        setEntities={setEntities}
        recordEvent={(event: any) => {
          if (!OMNILYTICS_DISABLED)
            recordEvent(event);
        }}
      />
    )
  }

  if (showLayout) {
    WrappedComponent.Layout = defaultLayout;
  }
  return WrappedComponent
}
