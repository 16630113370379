import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: process.env.NEXT_APP_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_APP_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
let analytics: any
let remoteConfig: any

if (typeof window !== 'undefined') {
  analytics = getAnalytics(app)

  // Initialize Remote Config
  remoteConfig = getRemoteConfig(app)
  remoteConfig.settings = {
    minimumFetchIntervalMillis: process.env.NEXT_APP_FIREBASE_FETCH_INTERVAL_MILLISECOND, // Fetch config at least once per hour
  }
}

// Function to fetch Remote Config
export const fetchRemoteConfig = async () => {
  if (remoteConfig) {
    try {
      await fetchAndActivate(remoteConfig)
      return remoteConfig
    } catch (error) {
      console.error(':: error ', error)
    }
  } else {
    console.error('Remote Config is not initialized.')
  }
}

export default remoteConfig