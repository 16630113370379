import { IDeviceInfo } from '@components/ui/context'
import {
  BETTERCOMMERCE_DEFAULT_COUNTRY,
  BETTERCOMMERCE_DEFAULT_COUNTRY_CODE,
  NEXT_PAYMENT_METHODS,
} from '@components/utils/constants'
import {
  JusPay,
  PaymentMethodMode,
  PaymentMethodType,
} from '@components/utils/payment-constants'
import { JUSPAY_OFFERS_PROMO_CODE } from '@framework/utils/constants'
import {
  createJusPayCustomerIfNotExists,
  getJusPayPaymentMethods,
  listJusPayCards,
} from '@framework/utils/juspay-util'
import { toNumber } from '@framework/utils/lodash'
import { matchStrings, stringToNumber, tryParseJson } from '@framework/utils/parse-util'
import axios from '@services/axiosInterceptorInstance'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import { LocalStorage } from '@components/utils/payment-constants'
import { decrypt } from '@framework/utils/cipher'

const VALIDATE_CARD_FORM_KEY = 'allCardStatuses'
const VALIDATE_SAVED_CARDS_FORM_KEY = 'allSavedCardStatuses'

declare const window: any

interface IHandlePromises {
  mode: any
  deviceInfo: IDeviceInfo
}

export interface ICODTransactionHookProps {
  cartItems: any
  setBasketId: any
  getUserId: any
  associateCart: any
  isChannelStore: any
  setCartItems: any
  setOrderId: any
  storeId: any
  setAlert: any
  defaultBillingAddress: any
  defaultShippingAddress: any
  recordGA4PaymentInfo: any
  user: any
  isGoKwikUser: any
  addressesList: any
  trackMoEngageEvent: any
  confirmOrder: any
  dispatch: any
  eta: any
}

export interface IUseOrdersHookProps {
  isChannelStore: any
  storeBasketId: any
  basketId: any
  getUserId: any
  shippingCountry: any
  user: any
  cartItems: any
  isTestPayment: any
  setBillableAmount: any
  recordFacebookPaymentInfo: any
  setOrderInfo: any
  defaultShippingAddress: any
  applicableCoupon: any
  applicableOffers: any
  resetCartStorage: any
  paymentMethods: any
  juspayPaymentMethodName: any
  defaultBillingAddress: any
  setAlert: any
  generateNewBasketAndRedirect: any
  paymentMethod: any
}

export const IS_PAYMENT_REDIRECT_ENABLED = true

export const PAYMENT_TABS = [
  {
    key: 'preferredpayment',
    label: 'Preferred Payment',
    className: ({ selected }: any) =>
      classNames(
        'w-full py-4 sm:px-0 px-4 text-sm leading-5 text-blue-700 text-left pl-4 dark:text-black opacity-60',
        'focus:outline-none',
        selected
          ? 'border-r-2 border-black opacity-full font-bold'
          : 'text-blue-100 font-medium'
      ),
    children: (
      <span className="pr-2 leading-none align-middle">
        <i className="sprite-icon sprite-upi"></i>
      </span>
    ),
  },
  {
    key: 'upi-list',
    label: 'UPI',
    className: ({ selected }: any) =>
      classNames(
        'w-full py-4 sm:px-0 px-4 text-sm leading-5 text-blue-700 text-left pl-4 dark:text-black opacity-60',
        'focus:outline-none',
        selected
          ? 'border-r-2 border-black opacity-full font-bold'
          : 'text-blue-100 font-medium'
      ),
    children: (
      <span className="pr-2 leading-none align-middle">
        <i className="sprite-icon sprite-upi"></i>
      </span>
    ),
  },
  {
    key: 'cards-list',
    label: 'Credit & Debit Cards',
    className: ({ selected }: any) =>
      classNames(
        'w-full py-4 sm:px-0 px-4 text-sm leading-5 text-blue-700 text-left pl-4 dark:text-black opacity-60 tab-pay-btn cards-list',
        'focus:outline-none',
        selected
          ? 'border-r-2 border-black opacity-full font-bold'
          : 'text-blue-100 font-medium'
      ),
    children: (
      <span className="pr-2 leading-none align-middle">
        <i className="sprite-icon sprite-cards"></i>
      </span>
    ),
  },
  {
    key: 'wallets',
    label: 'Wallets',
    className: ({ selected }: any) =>
      classNames(
        'w-full py-4 sm:px-0 px-4 text-sm leading-5 text-blue-700 text-left pl-4 dark:text-black opacity-60',
        'focus:outline-none',
        selected
          ? 'border-r-2 border-black opacity-full font-bold'
          : 'text-blue-100 font-medium'
      ),
    children: (
      <span className="pr-2 leading-none align-middle">
        <i className="sprite-icon sprite-wallet"></i>
      </span>
    ),
  },
  {
    key: 'netbanking',
    label: 'Netbanking',
    className: ({ selected }: any) =>
      classNames(
        'w-full py-4 sm:px-0 px-4 text-sm leading-5 text-blue-700 text-left pl-4 dark:text-black opacity-60',
        'focus:outline-none',
        selected
          ? 'border-r-2 border-black opacity-full font-bold'
          : 'text-blue-100 font-medium'
      ),
    children: (
      <span className="pr-2 leading-none align-middle">
        <i className="sprite-icon sprite-netbanking "></i>
      </span>
    ),
  },
  {
    key: 'payondelivery',
    label: 'Pay on Delivery',
    className: ({ selected }: any) =>
      classNames(
        'w-full py-4 sm:px-0 px-4 text-sm leading-5 text-blue-700 text-left pl-4 dark:text-black opacity-60 tab-pay-btn payondelivery',
        'focus:outline-none',
        selected
          ? 'border-r-2 border-black opacity-full font-bold'
          : 'text-blue-100 font-medium'
      ),
    children: (
      <span className="pr-2 leading-none align-middle">
        <i className="sprite-icon sprite-pay-cash"></i>
      </span>
    ),
  },
]

export const NETBANKING_OPTIONS = [
  {
    key: 'NB_HDFC',
    icon: (
      <>
        <i className="sprite-icon sprite-hdfc"></i>
      </>
    ),
    name: 'HDFC',
    primary: true,
  },
  {
    key: 'NB_SBI',
    icon: (
      <>
        <i className="sprite-icon sprite-sbi"></i>
      </>
    ),
    name: 'SBI',
    primary: true,
  },
  {
    key: 'NB_ICICI',
    icon: (
      <>
        <i className="sprite-icon sprite-icici"></i>
      </>
    ),
    name: 'ICICI',
    primary: true,
  },
  {
    key: 'NB_KOTAK',
    icon: (
      <>
        <i className="sprite-icon sprite-kotak"></i>
      </>
    ),
    name: 'KOTAK',
    primary: true,
  },
  {
    key: 'NB_AXIS',
    icon: (
      <>
        <i className="sprite-icon sprite-axis"></i>
      </>
    ),
    name: 'AXIS',
    primary: true,
  },
]

export const WALLET_OPTIONS = [
  {
    key: 'AMAZONPAY',
    icon: (
      <>
        <i className="sprite-icon sprite-amazon-pay"></i>
      </>
    ),
    name: 'Amazon Pay',
    className: 'sprite-amazon-pay',
  },
  {
    key: 'PAYTM',
    icon: (
      <>
        <i className="sprite-icon sprite-paytm-w"></i>
      </>
    ),
    name: 'PayTM',
    className: 'sprite-paytm-w',
  },
  {
    key: 'PHONEPE',
    icon: (
      <>
        <i className="sprite-icon sprite-phonepay"></i>
      </>
    ),
    name: 'Phone Pe',
    className: 'sprite-phonepay',
  },
  {
    key: 'MOBIKWIK',
    icon: (
      <>
        <i className="sprite-icon sprite-mobikwik"></i>
      </>
    ),
    name: 'Mobikwik',
    className: 'sprite-mobikwik',
  },
  {
    key: 'FREECHARGE',
    icon: (
      <>
        <i className="sprite-icon sprite-freecharge"></i>
      </>
    ),
    name: 'Freecharge',
    className: 'sprite-freecharge',
  },
]

export const MOBILE_UPI_OPTIONS: Array<any> = [
  {
    key: 'GOOGLEPAY',
    upiId: '',
    className: 'sprite-gpay',
    methodName: 'Google Pay',
  },
  {
    key: 'PHONEPE',
    upiId: '',
    className: 'sprite-phonepay',
    methodName: 'Phone Pe',
  },
  {
    key: 'PAYTM',
    upiId: '',
    className: 'sprite-paytm',
    methodName: 'Paytm',
  },
  {
    key: 'CRED',
    upiId: '',
    className: 'sprite-cred',
    methodName: 'CRED',
  },
]

export interface IOffersInput {
  readonly methodType: PaymentMethodType
  readonly methodRef: string
  readonly extras?: any // For - card_number, card_token, payment_method, txn_type,
}

export interface IJusPayOffersProps {
  readonly appliedCoupon: any
  readonly applicableCoupon: any
  readonly allCardStatuses?: Array<any>
  setAppliedCoupon?: any
  setApplicableCoupon?: any
  onQueryJusPayOffers?: Function
}

export interface IOrderProps {
  readonly isOrderGenerated: boolean
}

export const allCardStatuses = () => {
  if (localStorage?.getItem(VALIDATE_CARD_FORM_KEY)) {
    return JSON.parse(localStorage?.getItem(VALIDATE_CARD_FORM_KEY) as string)
  }
  return []
}

export const setAllCardStatuses = (statuses: Array<any>) => {
  localStorage.setItem(VALIDATE_CARD_FORM_KEY, JSON.stringify(statuses))
}

export const allSavedCardStatuses = () => {
  if (localStorage?.getItem(VALIDATE_SAVED_CARDS_FORM_KEY)) {
    return JSON.parse(
      localStorage?.getItem(VALIDATE_SAVED_CARDS_FORM_KEY) as string
    )
  }
  return []
}

export const setAllSavedCardStatuses = (statuses: Array<any>) => {
  localStorage.setItem(VALIDATE_SAVED_CARDS_FORM_KEY, JSON.stringify(statuses))
}

export const getAdditionalCharges = (method: any) => {
  const codStateEncrypted = localStorage?.getItem(LocalStorage.Key.COD_STATE) ?? '';
  if (codStateEncrypted) {
    const codState: any = codStateEncrypted ? tryParseJson(decrypt(codStateEncrypted)) : {};
    if (codState?.codValue) {
      return codState.codValue;
    }
  }
  if (method?.settings?.length) {
    const value = method?.settings?.find((x: any) =>
      matchStrings(x?.key, 'AdditionalServiceCharge', true)
    )?.value
    if (value && value.trim() !== '') {
      return toNumber(value).toFixed(2)
    }
  }
  return 0
}

export const getJusPayOfferDetails = ({
  applicableCoupon,
  applicableOffers,
}: any) => {
  if (applicableCoupon?.offers?.length) {
    const offerId = applicableCoupon?.offers[0]?.offer_id
    if (offerId) {
      const offer = applicableOffers?.find((x: any) =>
        matchStrings(x?.offer_id, offerId, true)
      )
      const offerCode = offer?.offer_code ?? ''
      const value =
        offer?.order_breakup?.benefits?.length > 0
          ? offer?.order_breakup?.benefits[0]?.value?.toString()
          : ''
      const calcRule =
        offer?.order_breakup?.benefits?.length > 0
          ? offer?.order_breakup?.benefits[0]?.calculation_rule
          : ''
      const discountedTotal = offer?.order_breakup?.final_order_amount ?? ''

      return {
        voucherCode: JUSPAY_OFFERS_PROMO_CODE,
        offerCode: offerCode,
        value: value,
        status:
          calcRule === JusPay.Offers.CalculationRuleType.ABSOLUTE
            ? 'false'
            : 'true',
        discountedTotal: stringToNumber(discountedTotal.toString()),
      }
    }
  }
  return ''
}

export const fetchJusPayPaymentMethods = async () => {
  const user = localStorage?.getItem(LocalStorage.Key.USER)
  const userDetails: any = user ? tryParseJson(user) : {}
  const userId = userDetails?.userId
  const authToken = localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
  const paymentMethodsResult = await getJusPayPaymentMethods({
    authToken,
    userId,
  })
  return paymentMethodsResult
}

export const handleCreateCustomer = async () => {
  const authToken =
    localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
  const userVal = localStorage?.getItem('user')
  const userDetails: any = userVal ? tryParseJson(userVal) : {}
  const userId = userDetails?.userId

  const { data: createCustomerResult } = await createJusPayCustomerIfNotExists(
    {
      mobileCountryCode: BETTERCOMMERCE_DEFAULT_COUNTRY_CODE ?? '',
      user: userDetails,
    },
    authToken,
    userId
  )
  return createCustomerResult
}

export const fetchPaymentMethod = async (
  cartItems: any,
  shippingCountry: any
) => {
  const response: any = await axios.post(NEXT_PAYMENT_METHODS, {
    currencyCode: cartItems?.baseCurrency,
    countryCode:
      shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
    basketId: cartItems?.id,
  })
  return response
}

export const handlePromises = (props: IHandlePromises) => {
  const { mode, deviceInfo } = props
  const { isMobile, isIPadorTablet } = deviceInfo

  if (mode) {
    let selector = ''
    if (matchStrings(mode as string, PaymentMethodMode.CARD.toString(), true)) {
      selector = 'button.tab-pay-btn.cards-list'
    } else if (
      matchStrings(mode as string, PaymentMethodMode.COD.toString(), true)
    ) {
      if (isMobile || isIPadorTablet) {
        selector = 'button#headlessui-disclosure-button-9'
      } else {
        selector = 'button.tab-pay-btn.payondelivery'
      }
    }

    if (selector) {
      const btn: any = document.querySelector(selector)
      if (btn) {
        setTimeout(() => {
          btn.click()
        }, 100)
      }

      if (isMobile || isIPadorTablet) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  }
}

  export const invokeJusPayCards = async () => {
    const authToken = localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const user = localStorage?.getItem(LocalStorage.Key.USER)
    const userDetails: any = user ? tryParseJson(user) : {}
    const input = {
      customer_id: userDetails?.userId,
    }
    const userId = userDetails?.userId
    // called without awaiting
    listJusPayCards(input, authToken, userId)
  }