import InfoLink from '../Footer/InfoLink'
import { useEffect, useState } from 'react'
import { EmptyGuid } from '@components/utils/constants'
import { useUI } from '@components/ui'
import { AppleStoreIcon, GooglePlayIcon } from '@components/icons'
import { useRouter } from 'next/router'
import { getAppsFlyerDynamicLinkFromCookiesOnPageReLoad } from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'

export default function Newsletter({ deviceInfo }: any) {
  const [err, setErr] = useState<any>(null)
  const { setAlert, user } = useUI()
  const router = useRouter()
  const [dynamicLink, setDynamicLink] = useState('')
  const [appleDynamicLink, setAppleDynamicLink] = useState('')
  const { asPath } = router

  useEffect(() => {
    if (!dynamicLink) {
      setDynamicLink(getAppsFlyerDynamicLinkFromCookiesOnPageReLoad())
    }
  }, [getAppsFlyerDynamicLinkFromCookiesOnPageReLoad()])

  useEffect(() => {
    if (!appleDynamicLink) {
      setAppleDynamicLink(getAppsFlyerDynamicLinkFromCookiesOnPageReLoad())
    }
  }, [getAppsFlyerDynamicLinkFromCookiesOnPageReLoad()])

  useEffect(() => {
    setTimeout(() => {
      const androidDownloadAppLinkElement: HTMLAnchorElement =
        document.getElementById('androidDownloadApp') as HTMLAnchorElement
      const iosDownloadAppLinkElement: HTMLAnchorElement =
        document.getElementById('iosDownloadApp') as HTMLAnchorElement
      if (dynamicLink !== '') {
        if (androidDownloadAppLinkElement) {
          androidDownloadAppLinkElement.setAttribute('href', dynamicLink)
          androidDownloadAppLinkElement.href = dynamicLink
        }
        if (iosDownloadAppLinkElement) {
          iosDownloadAppLinkElement.setAttribute('href', appleDynamicLink)
          iosDownloadAppLinkElement.href = appleDynamicLink
        }
      }
    }, 2000)
  }, [dynamicLink, asPath])

  useEffect(() => {
    if (err) setTimeout(() => setErr(null), 3000)
  }, [err])

  const onIconClick = (detail: any) => {
    recordGA4Event(window, GA_EVENT.NEWS_LETTER, {
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
  const downloadGAEvent = (platform: string) => {
    if (typeof window !== 'undefined') {
      recordGA4Event(window, 'download_app', {
        platform: platform,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }
  }

  return (
    <div className="grid grid-cols-1 gap-4 px-2 py-6 sm:gap-8 sm:grid-cols-2 sm:px-6">
      <div className="block w-full sm:hidden">
        <InfoLink
          onIconClick={(section: string) => {
            onIconClick(section)
          }}
        />
      </div>
      {/* border-b-2 border-gray-300 sm:border-0 */}
      <div className="w-full py-0 pb-2 order-1 sm:order-1 md:order-1 lg:order-1">
        <h3 className="pb-1 text-base font-semibold dark:text-black">
          Contact Us
        </h3>
        <p className="mb-3 text-sm font-normal">
          Need help? Get in touch with us and we’ll be more than happy to guide
          you through!
        </p>
        <div className="w-full my-4">
          <a
            href="mailto:support@damensch.com"
            className="block mb-3 text-sm underline"
          >
            support@damensch.com
          </a>
        </div>
        <div className="w-full">
          <a
            href="https://api.whatsapp.com/send/?phone=917829966655&text=Hi%2C+I+need+some+help"
            rel="noreferrer"
            target="_blank"
            className="block text-sm underline"
          >
            Chat with us
          </a>
        </div>
      </div>
      <div className="w-full py-0 pb-2 border-b-2 border-gray-300 sm:border-0 order-2 sm:order-2 md:order-2 lg:order-2">
        <h3 className="pb-1 text-base font-semibold dark:text-black">
          Experience the DaMENSCH Mobile App
        </h3>
        <div className="w-full my-4 flex justify-start items-center gap-3.5 w-32 h-16">
          <a
            href={'https://damensch.co.in/footer'}
            id="androidDownloadApp"
            target="_blank"
            className="block mb-3 w-full h-full"
            onClick={() => {
              downloadGAEvent('Google Play Store')
            }}
          >
            <GooglePlayIcon className="w-full h-full" />
          </a>
          <a
            href={'https://damensch.co.in/footer'}
            id="iosDownloadApp"
            target="_blank"
            className="block mb-3 w-full h-full"
            onClick={() => {
              downloadGAEvent('App Store')
            }}
          >
            <AppleStoreIcon className="w-full h-full" />
          </a>
        </div>
      </div>
    </div>
  )
}
