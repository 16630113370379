export enum GA_EVENT {
  ADD_NEW_ADDRESS = 'add_new_address',
  ADD_NEW_ADDRESS_CLICK = 'add_new address_click',
  ADD_NEW_ADDRESS_SCREEN_VIEWED = 'add_new_address_screen_viewed',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADDRESS_CHANGE = 'address_change',
  ADDRESS_CHANGES = 'address_changes',
  APPLY_COUPON = 'apply_coupon',
  AWESOME_BUTTON = 'awesome_button',

  BACK_BUTTON = 'back_button',
  BANNER_CLICK = 'banner_click',
  PLP_BANNER_CLICK = 'plp_banner_click',
  PLP_BANNER_IMPRESSIONS = 'plp_banner_impressions',
  BANNER_IMPRESSIONS = 'banner_impressions',
  BANNER_VISITS = 'banner_visits',
  BEGIN_CHECKOUT = 'begin_checkout',
  BLOG_CLICK = 'blog_click',
  COD_UPI = 'cod_upi',
  COD_CONFIRMATION = 'cod_confirmation',
  PAYMENT_PAGE_CONFIRMATION = 'payment_page_confirmation',
  EXIT_PAYMENT_PAGE = 'exit_payment_page',

  CANCEL_CONFIRM = 'cancel_confirm',
  CANCEL_FAIL = 'cancel_fail',
  CHECK_PINCODE = 'check_pincode',
  CHECKOUT_PAYMODE = 'checkout_paymode',
  CLOSE_BUTTON = 'close_button',
  COLOR_CHANGE = 'color_change',
  COPY_COUPON_CODE = 'copy_coupon_code',
  COPY_REFERRAL_CODE = 'copy_referral_code',
  CHOOSE_YOUR_GIFT_CLICK = 'choose_your_gift_click',
  CHANGE_BUTTON_CLICK = 'change_button_click',
  COPY_CODE_PROMPT = 'copy_code_prompt',

  DOWNLOAD_APP = 'download_app',

  EDIT_ADDRESS_SCREEN_VIEWED = 'edit_address_screen_viewed',
  EXCHANGE_CONFIRM = 'exchange_confirm',
  EXIT_POPUP_VIEW = 'exit_popup_view',
  EXCHANGE_THIS_ITEM_CLICK = 'exchange_this_item_click',

  FAILED_TRANSACTION = 'failed_transaction',
  FILTER_CLICK = 'filter_click',
  FOOTER_QUERY_CLICK = 'footer_query_click',
  FREE_GIFT_SCREEN_VIEWED = 'free_gift_screen_viewed',

  HAMBURGER_ICON_CLICK = 'hamburger_icon_click',
  HAMBURGER_MENU = 'hamburger_menu',
  HAMBURGER_MENU_CLICK = 'hamburger_menu_click',
  HAVE_REFERRAL_CODE = 'have_referral_code',
  HELP_ICON = 'help_icon',
  HELP_SIDEBAR_MENU = 'help_sidebar_menu',

  INITIATE_PAYMENT = 'initiate_payment',

  LOGIN_ATTEMPT = 'login_attempt',
  LOGO_CLICK = 'logo_click',
  LOGIN_SUCCESS = 'login_success',
  LOGIN_PROMPT = 'login_prompt',

  MENU_LIST = 'menu_list',
  MY_SAVED_ADDRESS_SCREEN_VIEWED = 'my_saved_address_screen_viewed',
  MY_SAVED_ADDRESS_MEGA_MENU = 'my_saved_address_mega_menu',
  MYADDRESS_SIDEPANEL = 'myaddress_sidepanel',

  NEED_HELP_WITH_YOUR_ORDER = 'need_help_with_your_order',
  NEWS_LETTER = 'news_letter',
  NOTIFY_CLICK = 'notify_click',
  NOTIFY_ME = 'notify_me',

  OFFER_POPUP = 'offer_popup',
  OTP_SENT = 'otp_sent',
  OTP_SUBMIT = 'otp_submit',
  OTP_REQUEST = 'otp_request',

  PAYMENT_FIRSTSTEP = 'payment_firststep',
  PAYMENT_INFO_SCREEN_VIEWED = 'payment_info_screen_viewed',
  PAYMODE_INFO = 'paymode_info',
  PDP_MENU = 'pdp_menu',
  POLICY_POPUP = 'policy_popup',
  POPUP_VIEW = 'popup_view',
  PROCEED_TO_CANCEL_ITEM = 'proceed_to_cancel_item',
  PROCEED_TO_CANCEL_ORDER = 'proceed_to_cancel_order',
  PROCEED_TO_EXCHANGE_ITEM = 'proceed_to_exchange_item',
  PROCEED_TO_RETURN_ITEM = 'proceed_to_return_item',
  PURCHASE = 'purchase',

  PURCHASE_INITIATED_AFTER_FAILURE = 'purchase_initiated_after_failure',

  RATE_YOUR_EXPERIENCE = 'rate_your_experience',
  REFER_EARN = 'refer_earn',
  REFERRAL_AMOUNT = 'referral_amount',
  REFERRAL_SUCCESS = 'referral_success',
  REFERRER_BANNERS = 'referrer_banners',
  REMOVE_FROM_CART = 'remove_from_cart',
  REMOVE_ITEM = 'remove_item',
  RESEND_OTP = 'resent_otp',
  RETURN_CONFIRM = 'return_confirm',
  RETURN_FAIL = 'return_fail',
  REVIEW_ACTION = 'review_action',
  RETURN_THIS_ITEM_CLICK = 'return_this_item_click',
  RETURN_EXCHANGE_CLICK = 'return/exchange_click',

  SAVE_NEW_ADDRESS = 'save_new_address',
  SCROLL_DEPTH = 'SCROLL_DEPTH',
  SEARCH = 'search',
  SEARCH_INIT = 'search_init',
  SEARCH_RESULTS = 'search_results',
  SELECT_ADDRESS_SCREEN_VIEWED = 'select_address_screen_viewed',
  SELECT_ITEM = 'select_item',
  SELECT_PROMOTION = 'select_promotion',
  SELECT_QUANTITY = 'select_quantity',
  SHARE_REFERRAL_CODE = 'share_referral_code',
  SIZE_CHANGE = 'size_change',
  SIZE_CHART = 'size_chart',
  SORT_AND_FILTER = 'sort_and_filter',
  SPECIFICATION_PRODUCT_DETAIL = 'specification_product_detail',
  SELECT_FREE_GIFT = 'select_free_gift',

  TRACK_PACKAGE = 'track_package',
  TOP_STRIP = 'top_strip',

  QUICK_VIEW_CLICK = 'quick_view_click',

  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_ORDER = 'view_order',
  VIEW_PROD_DETAILS = 'view_prod_details',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_REWARD = 'view_reward',
  PAGE_VIEW = 'page_view',

  WHATSAPP_OPTIN = 'whatsapp_optin',
  WISHLIST = 'wishlist',
  WELCOME_COPY_CODE = 'welcome_copy_code',

  // pickpaygo
  PPG_SCAN_BARCODE_CLICK = 'ppg_scan_barcode_click',
  PPG_SCAN_BARCODE = 'ppg_scan_barcode',
  PPG_ENTER_BARCODE = 'ppg_enter_barcode',
  PPG_ADD_TO_CART = 'ppg_add_to_cart',
  PPG_CLOSE_BUTTON = 'ppg_close_button',
  PPG_REMOVE_FROM_CART = 'ppg_remove_from_cart',
  PPG_SCAN_MORE_ITEMS = 'ppg_scan_more_items',
  PPG_OTP_REQUEST = 'ppg_otp_request',
  PPG_OTP_SUBMIT = 'ppg_otp_submit',
  PPG_BEGIN_CHECKOUT = 'ppg_begin_checkout',
  PPG_VIEW_PROMOTION = 'ppg_view_promotion',
  PPG_SELECT_PROMOTION = 'ppg_select_promotion',
  PPG_HAVE_REFERRAL_CODE = 'ppg_have_referral_code',
  PPG_ORDER_CONFIRM = 'ppg_order_confirm',
  PPG_INITIATE_PAYMENT = 'ppg_initiate_payment',
  PPG_PAYMENT_INFO_SCREEN_VIEWED = 'ppg_payment_info_screen_viewed',
  PPG_PAYMENT_FIRSTSTEP = 'ppg_payment_firststep',
  PPG_CHECKOUT_PAYMODE = 'ppg_checkout_paymode',
  PPG_ADD_PAYMENT_INFO = 'ppg_add_payment_info',
  PPG_PURCHASE = 'ppg_purchase',

  // daclub
  DACLUB_SCREEN_VIEWED = 'daclub_screen_viewed',
  GET_C0UPON_CODE = 'get_coupon_code',
  NOT_A_ROBOT = 'not_a_robot',

  // storelocator
  HEADER_CHANGE_STORE = 'header_change_store',
  CURRENT_LOCATION = 'current_location',
  CHOOSE_CITY = 'choose_city',
  ENTER_PINCODE = 'enter_pincode',
  GET_DIRECTION = 'get_direction',
  STORE_DETAILS = 'store_details',
  CALL_NOW = 'call_now',
  LOCATION_PERMISSION = 'location_permission',
  SKIP_PERMISSION = 'skip_permission',

  // rating feedback
  RATINGS = 'ratings',
  SHOPPING_RATINGS = 'shopping_ratings',
  PRODUCT_RATING = 'product_rating',
  EXPERIENCE_RATING = 'experience_rating',
  SUBMIT_EXPERIENCE_CLICK = 'submit_experience_click',
  EXPERIENCE_SUBMITTED = 'experience_submitted',
  SUBMIT_FEEDBACK_CLICK = 'submit_feedback_click',
  SUBMIT_FEEDBACK = 'submit_feedback',

  // my-orders screen
  RATE_DELIVERY_EXPERIENCE = 'rate_delivery_experience',
  HEADER_CHANGE = 'header_change',
  TRACKING_DETAILS = 'tracking_details',
  DOWNLOAD_INVOICE = 'download_invoice',
  DROPDOWN_ARROW = 'dropdown_arrow',
  CHOOSE_NEW_ITEM = 'choose_new_item',
  REQUEST_FOR_EXCHANGE_ITEM = 'request_for_exchange_item',
  CHAT_WITH_US = 'chat_with_us',
  EXCHANGE_FAIL = 'exchange_fail',
  REFUND_DETAILS_SUBMIT = 'refund_details_submit',
  REQUEST_FOR_RETURN_ITEM = 'request_for_return_item',

  VIEW_ELIGIBLE_PRODUCTS_CLICK = 'view_eligible_products_click',

  CLOSE_A2C_POPUP = 'close_a2c_popup',

  // // new promo code
  VIEW_PRODUCTS_CLICK = 'view_products_click',
  PROMOTION_POPUP_OPEN = 'promotion_popup_open',
  PROMO_ERROR = 'promo_error',

  // new offer product
  SOFT_ADDED_PRODUCT_VIEW = 'soft_added_product_view',

  // new nudge event
  BOTTOM_NUDGE_CLICK = 'bottom_nudge_click',
  BOTTOM_NUDGE_IMPRESSION = 'bottom_nudge_impression',

  //relogin pop up
  RELOGIN_POP_UP_APPEARS = 'relogin_popup',
  RELOGIN_POP_LOGIN_CLICKED = 'login_attempt',
  RELOGIN_POP_LOGOUT_CLICKED = 'logout_attempt',
  RELOGIN_POP_UP_DISAPPEARS = 'exit_relogin_popup',

  //CART relogin pop up
  CART_RELOGIN_POP_UP_APPEARS = 'login_popup_view',

  // KwikPass
  KP_ADDRESS_POPUP_VIEW = 'kp_address_popup_view',
  KP_ADDRESS_POPUP_CLICK = 'kp_address_popup_click'
}

export interface IGAOTPSent {
  current_page: string
}

export interface IGAOTPRequestAndSubmit {
  previous_page: string
  address_source: string
}

export interface IGAResendOTP {
  page_path: string
}

export interface IGAPolicyPopup {
  current_page: string
  category: string
  loggedin_status: boolean
}
